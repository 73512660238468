.profile_button {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid var(--border-color);
  background-color: var(--button-bg-color);
  box-shadow: 2px 2px 0px var(--shadow-border),4px 4px var(--shadow-color);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.05s;
  cursor: pointer;
}



.profile_button:hover {
  box-shadow: 2px 2px 0px var(--shadow-border),6px 6px var(--shadow-color); /* Larger shadow for a pronounced effect */
  transform: translate(-1px, -1px); /* Slight movement to mimic a raised effect */
  cursor: pointer;
}

.profile_button:active {
  box-shadow: 0px 0px var(--shadow-color); /* No shadow when pressed in */
  transform: translate(2px, 2px); /* Move it further down on click */
}

.picon {
  width: 24px;
  height: 24px;
  color: var(--text-color);
  background-color: transparent;
}
