/* Container for the language selection */
.paths-container {
  padding: 20px;
  display: flex; /* Use flexbox */
  flex-direction: column; /* Arrange children in a column */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Align content to the left */
  height: 100vh; /* Full viewport height for vertical centering */
  width: 100%; /* Full width of the viewport */
  margin-top: -80px;
}

/* Title styling */
.paths-container h2 {
  width: 100%; /* Ensure the title takes full width */
  text-align: left; /* Align text to the left */
  font-family: "Courier New", monospace;
  font-size: 28px;
  font-weight: 600;
  color: var(--text-color);
  margin-left: 10vw;
}

h3 {
    background-color: transparent;
    color: var(--signature-font-color);
}

/* Grid layout for language widgets */
.language-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 widgets in a row */
  gap: 20px;
  width: 90%; /* Ensure the grid takes full width */
}

/* Language widget styles */
.language-widget {
  display: flex;
  flex-direction: column;
  font-family: "Courier New", monospace;
  font-size: 18px;
  font-weight: 600;
  height: 25vh;
  align-items: center;
  justify-content: center;
  background-color: var(--signature-color);
  border: 1px solid var(--border-color);
  border-radius: 20px;
  box-shadow: 3px 3px 0px var(--shadow-border), 6px 6px 0px var(--shadow-color);
  padding: 20px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

/* Styling for flag icons */
.widget-icon {
  font-size: 5rem; /* Adjust the size as necessary */
  margin-bottom: 20px; /* Add spacing between the flag and text */
  display: block; /* Ensure block display for the span */
  width: 80px; /* Explicit width */
  height: 80px; /* Explicit height */
}

/* Hover effect */
.language-widget:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0px var(--shadow-border), 10px 10px 0px var(--shadow-color);
}
