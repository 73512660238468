body {
    overflow-y: scroll; /* Enable vertical scrolling */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
}

body::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.InfinyVocab {
    text-align: center;
    margin-top: 50px;
    font-family: "Courier New", monospace;
    font-style: bold;
}

.word-display {
    margin-bottom: 20px;
    color: var(--text-color);
}

/* Updated input styling to match Searchbar */
input[type="text"] {
    width: 100%;
    max-width: 300px;
    height: 15px;
    padding: 12px;
    font-size: 18px;
    font-family: "Courier New", monospace;
    color: var(--text-color);
    background-color: var(--input-color);
    border: 1px solid var(--border-color);
    border-radius: 50px;
    outline: none;
    transition: all 0.3s ease;
    box-shadow: 3px 3px 0px var(--shadow-border), 6px 6px 0 var(--shadow-color);
}

input[type="text"]::placeholder {
    color: var(--placeholder-color);
}

h1 {
    font-size: 30pt;
    color: var(--header-color);
    text-shadow: 2px 2px 0px var(--header-shadow-color);
}

input[type="text"]:hover {
    transform: translate(-2px, -2px);
    box-shadow: 5px 5px 0px var(--shadow-border), 10px 10px 0 var(--shadow-color);
}

input[type="text"]:focus {
    background-color: var(--input-color);
    color: var(--text-color);
    border-color: var(--border-color);
    animation: none;
}

input[type="text"]:focus::placeholder {
    color: var(--placeholder-color);
}

input[type="text"]:not(:placeholder-shown) {
    animation: none;
    font-weight: bold;
    letter-spacing: normal;
    text-shadow: none;
}

button {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 1px solid var(--border-color);
    background-color: var(--button-bg-color);
    box-shadow: 2px 2px 0px var(--shadow-border), 4px 4px var(--shadow-color);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.05s;
    cursor: pointer;
    margin-left: 10px;
}

button:hover {
    box-shadow: 2px 2px 0px var(--shadow-border), 6px 6px var(--shadow-color);
    transform: translate(-1px, -1px);
}

button:active, button.active {
    box-shadow: 0px 0px var(--shadow-color);
    transform: translate(2px, 2px);
}

.feedback {
    margin-top: 25px;
    font-size: 18px;
    color: var(--text-color); /* Updated to use text color variable */
}



.language-selector {
    margin-top: 0px;
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    gap: 10px; /* Add some space between the dropdowns */
}

/* Updated select styling to match LanguageSelector */
select {
    height: 30px; /* Thinner height */
    border: 1px solid var(--border-color);
    border-radius: 50px;
    background-color: var(--button-bg-color);
    box-shadow: 2px 2px 0px var(--shadow-border), 4px 4px var(--shadow-color);
    font-size: 14px;
    font-weight: 600;
    font-family: "Courier New", monospace;
    color: var(--text-color);
    padding: 5px 10px;
    display: inline-flex; /* Use inline-flex to allow content-based width */
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    outline: none;
    position: relative;
    margin: 0 5px; /* Add margin to separate the dropdowns */
}

select:hover {
    transform: translate(-1px, -1px); /* Slight movement to mimic a raised effect */
    box-shadow: 6px 6px var(--shadow-color); /* No shadow when pressed in */
}

select:active {
    box-shadow: 0px 0px var(--shadow-color); /* No shadow when pressed in */
    transform: translate(2px, 2px); /* Move it further down on click */
}

/* Style for the dropdown options */
select option {
    font-size: 14px;
    font-weight: 600;
    font-family: "Courier New", monospace;
    color: var(--text-color);
    background-color: var(--button-bg-color);
    padding: 5px 10px;
    cursor: pointer;
}

form {
    display: flex;
    align-items: center;
    justify-content: center; /* Center the input and button horizontally */
    margin-bottom: 20px;
}

.keyboard {
    margin-top: 90px; /* Increased margin to move the keyboard down */
}

.submit-icon {
    background-color: transparent;
    color: var(--text-color);
}

.correct-answer {
    color: var(--signature-color); /* Apply signature color to the correct answer */
}

.vocabulary-set-selector {
    position: absolute; /* Position it absolutely */
    top: 100px; /* Adjust the top position as needed */
    left: 20px; /* Adjust the left position as needed */
    background-color: transparent; /* Optional: Add a background color */
    padding: 10px; /* Optional: Add some padding */
    font-weight: bold; /* Make the text bold */
}

.score-display {
    position: absolute;
    bottom: 15px; /* Change from top to bottom */
    right: 15px;
    width: 50px; /* Slightly larger than the submit button */
    height: 50px;
    border-radius: 100%;
    border: 1px solid var(--border-color);
    background-color: var(--signature-color);
    box-shadow: 2px 2px 0px var(--shadow-border), 4px 4px var(--shadow-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: var(--text-color);
    transition: all 0.05s;
    z-index: 100;
    font-family: "Courier New", monospace;
}

.select-wrapper {
    position: relative;
    display: inline-block;
}

.select-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    display: flex;
    align-items: center;
}
