/* Wrapping label to enclose the toggle switch */
.toggle-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 20px;
  cursor: pointer;
  background-color: transparent;
}

/* Toggle input (hidden) */
.brutalist-toggle-input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Slider (the visible part of the toggle switch) */
.brutalist-toggle-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--button-bg-color);
  border: 1px solid var(--border-color);
  box-shadow: 4px 4px var(--shadow-color);
  border-radius: 5px;
  transition: 0.3s;
}

/* Circle inside the toggle */
.brutalist-toggle-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: var(--button-bg-color);
  border: 1px solid var(--border-color);
  box-shadow: 0 3px var(--border-color);
  border-radius: 5px;
  left: -2px;
  bottom: 2px;
  transition: 0.17s;
}

/* When checked, move the circle */
.brutalist-toggle-input:checked + .brutalist-toggle-slider {
  background-color: var(--widget-violet); /* Change the color as per your theme */
}

.brutalist-toggle-slider {
  background-color: var(--signature-color);
}


.brutalist-toggle-input:checked + .brutalist-toggle-slider:before {
  transform: translateX(30px);
}
