.reduced-navbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 25px; /* Adds spacing between the elements */
    padding: 10px 30px; /* Padding for the navbar */
    background-color: transparent; /* No background color */
    position: fixed; /* Stick to the top */
    top: 0;
    right: 0;
    z-index: 1000; /* Ensure it stays on top */
}