@import url('https://fonts.googleapis.com/css2?family=Lexend+Mega&display=swap');


html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
  width: 100vw;
}

.switch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 50px;
  height: 20px;
  position: relative;
}

.card-side::before {
  position: absolute;
  content: 'Log in';
  left: -70px;
  top: 0;
  width: 100px;
  text-decoration: underline;
  color: var(--text-color);
  font-weight: 600;
}

.card-side::after {
  position: absolute;
  content: 'Sign up';
  left: 70px;
  top: 0;
  width: 100px;
  text-decoration: none;
  color: var(--text-color);
  font-weight: 600;
}

.toggle {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  box-sizing: border-box;
  border-radius: 5px;
  border: 2px solid var(--border-color);
  box-shadow: 4px 4px var(--shadow-color);
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--input-color);
  transition: 0.3s;
}

.slider:before {
  box-sizing: border-box;
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  border: 2px solid var(--border-color);
  border-radius: 5px;
  left: -2px;
  bottom: 2px;
  background-color: var(--input-color);
  box-shadow: 0 3px 0 var(--border-color);
  transition: 0.3s;
}

.toggle:checked + .slider {
  background-color: var(--signature-color);
}

.toggle:checked + .slider:before {
  transform: translateX(30px);
}

.toggle:checked ~ .card-side:before {
  text-decoration: none;
}

.toggle:checked ~ .card-side:after {
  text-decoration: underline;
}

.flip-card__inner {
  width: 300px;
  height: 350px;
  position: relative;
  background-color: transparent;
  perspective: 1000px;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.toggle:checked ~ .flip-card__inner {
  transform: rotateY(180deg);
}

.flip-card__front,
.flip-card__back {
  padding: 20px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: var(--signature-color);
  gap: 20px;
  border: 2px solid var(--border-color);
  box-shadow: 12px 12px var(--shadow-color);
}

.flip-card__back {
  width: 100%;
  transform: rotateY(180deg);
}

.flip-card__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background-color: var(--signature-color);
}

.title {
  margin: 20px 0;
  margin-top: 0;
  font-size: 35px;
  font-weight: 800;
  text-align: center;
  color: var(--text-color);
  background-color: var(--signature-color);
}

.flip-card__input {
  width: 250px;
  height: 40px;
  border: 2px solid var(--border-color);
  background-color: var(--input-color);
  box-shadow: 4px 4px var(--shadow-color);
  font-size: 15px;
  font-weight: 600;
  font-family: "Courier New", monospace;
  color: var(--text-color);
  padding: 5px 10px;
  outline: none;
  transition: 0.2s;
}

.flip-card__input::placeholder {
  color: var(--placeholder-color);
  opacity: 1;
  font-family: "Courier New", monospace;
  font-weight: 500;
}

.flip-card__input:focus {
  transform: translate(-2px, -2px);
  box-shadow: 8px 8px 0 var(--shadow-color);
}

.flip-card__btn:hover {
  box-shadow: 6px 6px var(--shadow-color);
  transform: translate(-1px, -1px);
}

.flip-card__btn:active {
  box-shadow: 0px 0px var(--shadow-color);
  transform: translate(3px, 3px);
}


.button {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 2px solid var(--border-color);
  background-color: var(--button-bg-color);
  box-shadow: 4px 4px var(--shadow-color);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.05s;
  cursor: pointer;
  margin-bottom: 0px;
}

.flip-card__btn {
  margin: 20px 0;
  width: 120px;
  height: 40px;
  border: 2px solid var(--border-color);
  background-color: var(--input-color);
  box-shadow: 4px 4px var(--shadow-color);
  font-size: 17px;
  font-weight: 600;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.05s;
  margin-bottom: 20px;
  border-radius: 5px;
}

.button:hover {
  box-shadow: 6px 6px var(--shadow-color);
  transform: translate(-1px, -1px);
}

.button:active {
  box-shadow: 0px 0px var(--shadow-color);
  transform: translate(3px, 3px);
}

.icon {
  width: 24px;
  height: 24px;
  fill: var(--text-color);
  background-color: transparent;
  color: var(--text-color);
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  background-color: var(--signature-color);
}
