.stories-page {
  min-height: 100vh;
  background-color: var(--background-color);
}

.stories-content {
  display: flex;
}

.stories-sidebar {
  width: 27vw;
  background-color: var(--background-color);
  box-shadow: 6px 0 6px rgba(0, 0, 0, 0.1);
  border-right: 1px solid var(--border-color);
  padding: 20px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  z-index: 6;
}

.stories-container {
  flex: 1;
  padding-left: 20px;
  margin-left: 34vw;
  margin-right: 5vw;
  padding-top: 120px;
  padding-bottom: 80px;
  width: 76vw;
  align-items: center;
  justify-content: center;
  position: relative;
}

.stories-container::before,
.stories-container::after {
  content: '';
  position: fixed;
  left: 31vw;
  right: 0;
  height: 110px;
  pointer-events: none;
  z-index: 4;
}

.stories-container::before {
  top: 0;
  background: linear-gradient(to bottom, 
    var(--background-color) 0%,
    var(--background-color) 50%,
    rgba(var(--background-color-rgb), 0) 100%
  );
}

.stories-container::after {
  bottom: 0;
  background: linear-gradient(to top, 
    var(--background-color) 0%,
    var(--background-color) 40%,
    rgba(var(--background-color-rgb), 0) 100%
  );
}

.story-card {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--button-bg-color);
  padding: 10px 20px;
  border-radius: 40px;
  margin-bottom: 15px;
  cursor: pointer;
  font-family: "Courier New", monospace;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  border: 1px solid var(--border-color);
  box-shadow: 2px 2px 0px var(--shadow-border), 6px 6px 0px var(--shadow-color);
  height: 20px;
  min-height: 20px;
}

.story-card:hover {
  transform: translate(-2px, -2px);
  box-shadow: 4px 4px 0px var(--shadow-border), 10px 10px 0px var(--shadow-color);
}

.story-card:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0px var(--shadow-color);
}

.story-icon {
  margin-right: 20px;
  min-width: 24px;
  color: var(--text-color);
}

.story-info {
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.story-level {
  font-weight: bold;
  color: var(--text-color);
  font-family: "Courier New", monospace;
  font-size: 16px;
  width: 50px;
  min-width: 50px;
  margin-left: 10px;
  margin-right: 0px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}



.story-title {
  width: 250px;
  color: var(--text-color);
  font-family: "Courier New", monospace;
  font-weight: normal;
  font-size: 16px;
  display: block;
  background-color: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  margin-right: 50px;
  line-height: 20px;
  position: relative;
  flex-shrink: 0;
  text-align: left;
  padding-left: 0;
  z-index: 1;
}

.story-duration {
  color: var(--text-color);
  margin: 0 20px;
  white-space: nowrap;
  font-family: "Courier New", monospace;
  margin-left: auto;
  z-index: 3;
}

.stories-header {
  position: fixed;
  top: 0;
  left: 29vw;
  right: 0;
  height: 80px;
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  padding-left: 6vw;
  z-index: 5;
}

.stories-header h2 {
  font-family: "Courier New", monospace;
  font-size: 28px;
  color: var(--text-color);
  margin-left: 15px;
  font-weight: bold;
}

/* Update for different card types */
.chat-card .story-level::before,
.article-card .story-level::before {
  background-color: var(--button-bg-color);
}

/* Add these styles for the sidebar niveau section */
.sidebar-niveau-section {
    width: 80%;
    padding: 20px;
    margin-top: 20px;
    background-color: var(--button-bg-color);
    border: 1px solid var(--border-color);
    border-radius: 30px;
    box-shadow: 2px 2px 0px var(--shadow-border), 4px 4px var(--shadow-color);
}

.sidebar-niveau-section .niveau-labels-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: -10px;
    margin-left: -12px;
    padding: 0;
    position: relative;
    width: calc(100% - 20px);
    margin-top: 10px;
}

.sidebar-niveau-section .niveau-label {
    font-size: 12px;
    color: var(--text-color);
    position: absolute;
    transform: translateX(-50%);
    background-color: transparent;
    left: calc(var(--left-position) - 8%);
    top: 5px;
    font-family: "Courier New", monospace;
    font-weight: bold;
}

.sidebar-niveau-section .react-slider {
    margin: 40px 0 65px 0;
}

.sidebar-niveau-section .react-slider .thumb {
    background-color: var(--button-bg-color);
    border: 1px solid var(--border-color);
    box-shadow: 0 2px var(--border-color);
    border-radius: 5px;
    cursor: grab;
    top: 50%;
    transform: translate(0, -50%);
    outline: none;
    width: 16px;
    height: 16px;
    position: relative;
}

.sidebar-niveau-section .react-slider .thumb:hover {
    transform: translate(0, -52%);
}

.sidebar-niveau-section .react-slider .thumb:active {
    cursor: grabbing;
    box-shadow: 0 1px var(--border-color);
    transform: translate(0, -49%);
}

.sidebar-niveau-section .react-slider .track {
    background: var(--button-bg-color);
    height: 4px;
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid var(--border-color);
}

.sidebar-niveau-section .react-slider .track-1 {
    background: var(--signature-color);
}

.sidebar-niveau-section .niveau-label-header {
    display: block;
    text-align: left;
    font-family: "Courier New", monospace;
    font-size: 10pt;
    font-weight: bold;
    color: var(--text-color) !important;
    margin-bottom: 5px;
    background-color: transparent;
}

/* Add these styles for the duration slider */
.sidebar-niveau-section:nth-child(3) { /* Target the duration slider container */
    margin-top: 10px; /* Add some space between the sliders */
}

.sidebar-niveau-section:nth-child(3) .niveau-labels-container {
    margin-left: 0; /* Adjust for different label positioning */
}

.sidebar-niveau-section:nth-child(3) .niveau-label {
    left: calc(var(--left-position)) !important; /* Override the level slider positioning */
}

/* Add these styles for the value indicators */
.sidebar-niveau-section .react-slider .thumb {
    position: relative;
}

.sidebar-niveau-section .react-slider .slider-value {
    position: absolute;
    top: 20px; /* Position below the thumb */
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--button-bg-color);
    border: 1px solid var(--border-color);
    padding: 2px 6px;
    border-radius: 10px;
    font-size: 12px;
    white-space: nowrap;
    color: var(--text-color);
    font-family: "Courier New", monospace;
    pointer-events: none;
    box-shadow: 1px 1px 0px var(--shadow-border);
}

/* Adjust the bottom margin to make room for the value display */
.sidebar-niveau-section .react-slider {
    margin: 40px 0 65px 0;
}

/* Language section styles */
.languages-section {
    margin-bottom: 15px;
    background-color: transparent;
}

.selected-languages {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.language {
    height: 20px;
    display: flex;
    align-items: center;
    background-color: var(--button-bg-color);
    border: 1px solid var(--border-color);
    border-radius: 12px;
    padding: 3px 7px;
    cursor: pointer;
    transition: background-color 0.2s;
    font-family: "Courier New", monospace;
    font-weight: normal;
    font-size: 10pt;
    color: var(--text-color);
}

.language:hover {
    background-color: #ff5c5c;
    text-decoration: line-through;
}

.add-language {
    height: 26px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--button-bg-color);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.05s;
    font-family: "Courier New", monospace;
    font-weight: normal;
    font-size: 12pt;
    color: var(--text-color);
    padding: 0;
    margin-left: 0px;
    box-shadow: none;
}

.add-language:hover {
    transform: none;
    box-shadow: none;
    font-weight: bold;
}

.add-language:active {
    transform: none;
    box-shadow: none;
}

/* The rest of the modal styles should already be in your CSS from VocabCreator */

/* Update modal styles */
.topics-modal {
    z-index: 1000 !important; /* Ensure it's above the sidebar */
    max-height: 70vh !important; /* Limit height to prevent off-screen content */
    overflow-y: auto !important;
}

.niveau-modal-overlay {
    z-index: 999 !important; /* Ensure overlay is also above sidebar but below modal */
}

.types-section {
    margin-bottom: 15px;
    background-color: transparent;
}

.selected-types {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.type {
    height: 20px;
    display: flex;
    align-items: center;
    background-color: var(--button-bg-color);
    border: 1px solid var(--border-color);
    border-radius: 12px;
    padding: 3px 7px;
    cursor: pointer;
    transition: background-color 0.2s;
    font-family: "Courier New", monospace;
    font-weight: normal;
    font-size: 10pt;
    color: var(--text-color);
}

.type:hover {
    background-color: #ff5c5c;
    text-decoration: line-through;
}

/* Adjust the gap between sections */
.stories-sidebar {
    width: 27vw;
    background-color: var(--background-color);
    box-shadow: 6px 0 6px rgba(0, 0, 0, 0.1);
    border-right: 1px solid var(--border-color);
    padding: 20px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    z-index: 6;
}

/* Adjust spacing between niveau sections specifically */
.sidebar-niveau-section + .sidebar-niveau-section {
    margin-top: 10px;
}
