.lesson-page {
    min-height: 100vh;
    background-color: var(--background-color);
}

.content {
    display: flex;
}

.sidebar {
    width: 27vw;
    background-color: var(--background-color);
    box-shadow: 6px 0 6px rgba(0, 0, 0, 0.1);
    border-right: 1px solid var(--border-color);
    padding: 20px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    z-index: 6;
}

.sidebar .input-container {
    margin-top: 10px;
    width: 80%;
}

.main-content {
    flex: 1;
    margin-left: 27vw;
    position: relative;
}

.lesson-header {
    position: fixed;
    top: 0;
    left: 29vw;
    right: 0;
    height: 80px;
    background-color: var(--background-color);
    display: flex;
    align-items: center;
    padding-left: 6vw;
    z-index: 5;
}

.lesson-header h2 {
    font-family: "Courier New", monospace;
    font-size: 24px;
    color: var(--text-color);
    font-weight: normal;
}

.lesson-container {
    padding-left: 20px;
    margin-left: 6vw;
    margin-right: 5vw;
    padding-top: 120px;
    padding-bottom: 80px;
    position: relative;
}

.lesson-container::before,
.lesson-container::after {
    content: '';
    position: fixed;
    left: 31vw;
    right: 0;
    height: 110px;
    pointer-events: none;
    z-index: 4;
}

.lesson-container::before {
    top: 0;
    background: linear-gradient(to bottom, 
        var(--background-color) 0%,
        var(--background-color) 50%,
        rgba(var(--background-color-rgb), 0) 100%
    );
}

.lesson-container::after {
    bottom: 0;
    background: linear-gradient(to top, 
        var(--background-color) 0%,
        var(--background-color) 40%,
        rgba(var(--background-color-rgb), 0) 100%
    );
}
