.keyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: scale(1.4); /* Scale the entire keyboard up by 1.5 */
  width: 100%; /* Make the keyboard take the full width of the viewport */
  max-width: 600px; /* Set a maximum width for larger screens */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  margin: 20px auto; /* Center the keyboard horizontally and add top margin */
  margin-top: 80px; /* Center the keyboard horizontally and add top margin */
}


@media (max-width: 900px) {
  .keyboard {
    transform: scale(1.1); /* Reset scaling on smaller screens */
    margin: 40px auto; /* Adjust margin for smaller screens if needed */
  }
}

/* Add media query for mobile responsiveness */
@media (max-width: 600px) {
  .keyboard {
    transform: scale(0.9); /* Reset scaling on smaller screens */
    margin: 10px auto; /* Adjust margin for smaller screens if needed */
  }
}

/* Add media query for mobile responsiveness */
@media (max-width: 500px) {
  .keyboard {
    transform: scale(0.8); /* Reset scaling on smaller screens */
    margin: 10px auto; /* Adjust margin for smaller screens if needed */
  }
}

@media (max-width: 420px) {
  .keyboard {
    transform: scale(0.7); /* Reset scaling on smaller screens */
    margin: 10px auto; /* Adjust margin for smaller screens if needed */
  }
}

@media (max-width: 360px) {
  .keyboard {
    transform: scale(0.6); /* Reset scaling on smaller screens */
    margin: 10px auto; /* Adjust margin for smaller screens if needed */
  }
}

@media (max-width: 315px) {
  .keyboard {
    transform: scale(0.5); /* Reset scaling on smaller screens */
    margin: 10px auto; /* Adjust margin for smaller screens if needed */
  }
}

@media (max-width: 250px) {
  .keyboard {
    transform: scale(0.4); /* Reset scaling on smaller screens */
    margin: 10px auto; /* Adjust margin for smaller screens if needed */
  }
}

.keyboard-row {
  display: flex;
  margin-bottom: 5px;
}

.keyboard-key {
  width: 40px;
  height: 40px;
  border-radius: 5px; /* Slightly rounded corners */
  border: 1px solid var(--border-color);
  background-color: var(--button-bg-color);
  box-shadow: 2px 2px 0px var(--shadow-border), 4px 4px var(--shadow-color);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.05s;
  cursor: pointer;
  margin: 3px;
  font-size: 0.9em; /* Make letters larger */
  font-weight: bold; /* Make letters bold */
  color: var(--text-color);
  font-family: "Courier New", monospace;
}

.keyboard-key.pressed {
  box-shadow: 0px 0px var(--shadow-color); /* No shadow when pressed in */
  transform: translate(2px, 2px); /* Move it further down on click */
}

.keyboard-key:hover {
  box-shadow: 3px 3px 0px var(--shadow-border), 6px 6px var(--shadow-color); /* Larger shadow for a pronounced effect */
  transform: translate(-1px, -1px); /* Slight movement to mimic a raised effect */
}

.keyboard-key:active {
  box-shadow: 0px 0px var(--shadow-color); /* No shadow when pressed in */
  transform: translate(2px, 2px); /* Move it further down on click */
}

.keyboard-key.shift {
  width: 120px; /* Make Shift button wider */
}

.keyboard-key.space {
  width: 200px; /* Make Space button wider */
}
