/* General navbar styles */
.navbar {
    position: fixed; /* Stick to the top */
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between; /* Distribute space between left and right sections */
    height: 10vh;
    align-items: center; /* Center elements vertically */
    padding: 10px 30px; /* Padding inside the navbar */
    background-color: transparent; /* No color except the background */
    z-index: 1000; /* Ensure it stays on top */
}

/* Left part - containing the search bar */
.navbar-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 70px;
    background-color: transparent;
}

/* Right part - containing the toggle and profile button */
.navbar-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 25px; /* Adds more spacing between the elements */
    flex-shrink: 0; /* Prevent the elements from shrinking and overlapping */
    background-color: transparent;
}

/* Adjust logo size and spacing */
.navbar-logo {
  width: auto; /* Adjust the width as needed */
  height: 65px;
  margin-bottom: 0px; /* Add space between the logo and the search bar */
}


/* Ensures that elements in the navbar-right don't overlap */
.navbar-right > * {
    flex-shrink: 0; /* Prevent elements from shrinking */
}



#ZemtsAcademy {
    background: none; /* Removes background color */
    border: none; /* Removes borders */
    padding: 0; /* Removes default padding */
    margin-top: 0;
    font-size: 45px;
    font-weight: 800;
    text-shadow: 3px 3px 0px var(--shadow-border), 3px 3px var(--shadow-color);
    -webkit-text-stroke: 1px var(--border-color);
    text-align: center;
    color: var(--signature-color);
    background-color: var(--card-bg);
    text-align: left; /* Ensures the text is centered */
    width: 490px; /* Button width adjusts to the text */
    height: 60px; /* Button height adjusts to the text */
    cursor: pointer; /* Keeps the cursor as pointer */
    transition: transform 0.15s ease, text-shadow 0.15s ease;
    text-decoration: none; /* Ensures no underline */
    box-shadow: none; /* Ensures no box shadow is applied */
}

#ZemtsAcademy:hover {
  text-shadow: 3px 3px 0px var(--shadow-border), 4px 4px var(--shadow-color); /* Larger shadow for a pronounced effect */
  transform: translate(-1px, -1px); /* Slight movement to mimic a raised effect */
}

#ZemtsAcademy:active {
  text-shadow: 0px 0px var(--shadow-color); /* No shadow when pressed in */
  transform: translate(2px, 2px); /* Move it further down on click */
}

/* Media query for mobile responsiveness */
@media (max-width: 1000px) {
    #ZemtsAcademy {
        display: none; /* Hide the button on smaller screens */
    }

    .navbar-left {
        gap: 60px; /* Adjust gap to allow searchbar to move left */
    }
}
