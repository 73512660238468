body {
    overflow-y: scroll; /* Enable vertical scrolling */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    background-color: var(--background-color);
}

body::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}


.vocabcreator-container h2 {
    width: 100%; /* Ensure the title takes full width */
    text-align: center; /* Align text to the left */
    font-family: "Courier New", monospace;
    font-size: 28px;
    font-weight: 600;
    color: var(--text-color);
}

.vocabcreator-container h3 {
    width: 100%; /* Ensure the title takes full width */
    text-align: center; /* Align text to the left */
    font-family: "Courier New", monospace;
    font-size: 22px;
    font-weight: 400;
    color: var(--text-color);
}


.vocabcreator-container {
    width: 100%; /* Ensure the title takes full width */
    text-align: center; /* Align text to the left */
    font-family: "Courier New", monospace;
    font-size: 18px;
    font-weight: 600;
    color: var(--text-color);
    margin-top: 50px;
    justify-content: center;
    background-color: transparent; /* Ensure background is transparent */
}


.vocabcreator-layout {
    display: flex;
    justify-content: center;
    margin-left: 40px;
    gap: 30px !important;
    background-color: transparent;
    width: calc(100% - 80px); /* Account for the layout margins */
}

/* Column containers */
.left-column, .middle-column, .right-column {
    display: flex;
    flex-direction: column;
    width: calc((100% - 40px) / 3); /* Equal thirds minus the gaps */
    gap: 20px;
}

/* Update create-deck to remove its margin */
.create-deck {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0; /* Remove margin since gap handles spacing */
    background-color: var(--button-bg-color);
    border: 1px solid var(--border-color);
    border-radius: 30px;
    padding: 20px;
    padding-top: 0;
    height: 80vh;
    overflow-y: auto;
    box-sizing: border-box;
}

/* Custom scrollbar styles for WebKit browsers (Chrome, Safari) */
.create-deck::-webkit-scrollbar,
.modal-items::-webkit-scrollbar {
    width: 6px;
    background: transparent;
}

.create-deck::-webkit-scrollbar-thumb,
.modal-items::-webkit-scrollbar-thumb {
    background-color: #cecece;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: content-box;
}

.create-deck::-webkit-scrollbar-track,
.modal-items::-webkit-scrollbar-track {
    margin-top: 5vh;
    margin-bottom: 5vh;
    background-color: #f0f0f0;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: content-box;
}


.modal-items::-webkit-scrollbar-track {
    margin-top: 0vh;
    margin-bottom: 0vh;
}

/* Hide scrollbar buttons */
.create-deck::-webkit-scrollbar-button,
.modal-items::-webkit-scrollbar-button {
    height: 0;
    width: 0;
    display: none;
}

/* Ensure the scrollbar container respects the height */
.create-deck,
.modal-items {
    overflow-y: overlay;
}

.input-group {
    margin-bottom: 25px;
    background-color: transparent;
    width: 100% !important;
    box-sizing: border-box;
    padding: 10px 10px 0 10px;
}

.input-group label {
    display: block;
    margin-bottom: 10px;
    font-weight: normal;
    font-size: 10pt;
    background-color: transparent;
    text-align: left; /* Align text to the left */
}

.vocabcreator-container .vocabcreator-name-input {
    display: block !important; /* Add this to ensure full width behavior */
    width: 100% !important;
    box-sizing: border-box !important;
    border: none !important;
    border-bottom: 0.5px solid var(--border-color) !important;
    padding: 5px 0 5px 0 !important;
    margin: 0 !important;
    font-size: 11pt !important;
    border-radius: 0 !important;
    font-weight: normal !important;
    background-color: transparent !important; /* Ensure background is transparent */
    font-family: "Courier New", monospace !important; /* Ensure font is consistent */
    color: var(--text-color) !important; /* Ensure text color is consistent */
    transition: none !important; /* Disable transition effects */
    box-shadow: none !important; /* Ensure no shadow is applied */
}

.vocabcreator-container .vocabcreator-name-input:hover, .description-input:hover {
    box-shadow: none !important; /* No shadow on hover */
    transform: none !important; /* No transform on hover */
    transition: none !important; /* Disable transition effects */
}


.vocabcreator-container .description-input {
    width: 100% !important;
    box-sizing: border-box !important;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    font-size: 11pt !important;
    font-family: "Courier New", monospace !important;
    color: var(--text-color) !important;
    background-color: transparent !important; /* Ensure background is transparent */
    resize: none !important; /* Disable manual resizing */
    overflow-y: hidden !important;
    white-space: pre-wrap !important;
    word-wrap: break-word !important;
    transition: height 0.2s ease-out !important;
    box-shadow: none !important;
    line-height: 1.9; /* Adjust line height */
    min-height: 0.5em; /* Minimum height to show one line */
    background-image: linear-gradient(to bottom, var(--border-color) 0.5px, transparent 0);
    background-size: 100% 1.9em; /* Adjust the size to match line height */
    background-position: 0 1.7em; /* Adjust the vertical position of the underline */
}

.vocabcreator-container .description-input:focus {
    outline: none !important;
}

.vocabcreator-container .description-input::placeholder {
    color: lightgray !important;
}


.tags-section, .topics-section, .languages-section {
    margin-bottom: 15px;
    background-color: transparent;
    font-size: 12pt;
    font-weight: normal;
    padding: 0 10px 0 10px; 
}

.tags, .topics, .languages {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    background-color: transparent;
}

.tag, .topic, .language {
    cursor: not-allowed; /* Shows a "no" symbol when hovering */
    background-color: var(--signature-color);
    border-radius: 12px;
    padding: 3px 7px;
    font-weight: bold;
    font-size: 10pt;
    border: 1px solid var(--border-color);
    height: 24px;
    font-family: "Courier New", monospace;
    display: flex;
    align-items: center;
}

.add-tag, .add-topic, .add-language {
    cursor: pointer;
    color: var(--text-color);
    background-color: transparent !important;
    display: flex;  
    align-items: center;
    justify-content: center;
    padding: 3px 7px;
    border: none !important;
    transition: transform 0.04s, color 0.04s !important; /* Add transition for smooth effect */
}

.add-tag:hover, .add-topic:hover, .add-language:hover {
    background-color: transparent !important;
    transform: scale(1.1) !important; /* Make it 20% bigger on hover */
    color: var(--border-color) !important; /* Change color on hover */
}

.add-tag svg, .add-topic svg, .add-language svg {
    background-color: transparent !important;
}

.niveau-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the start */
    margin-bottom: 25px; /* Ensure consistent spacing */
    background-color: transparent;
    padding: 0 10px ; 
}

.niveau-dropdown {
    height: 30px;
    line-height: 30px; /* Vertically center text */
    border: 1px solid var(--border-color);
    background-color: var(--button-bg-color);
    box-shadow: 2px 2px 0px var(--shadow-border), 4px 4px var(--shadow-color);
    font-size: 14px;
    font-weight: 600;
    font-family: "Courier New", monospace;
    color: var(--text-color);
    padding: 0 10px 0 10px; /* Adjust padding to maintain vertical centering */
    cursor: pointer;
    outline: none;
    transition: all 0.05s;
    border-radius: 20px;
    width: 18%; /* Keep the original width */
    text-align: left; /* Align text to the left */
    appearance: none; /* Remove default dropdown arrow */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg>'); /* Add custom arrow */
    background-repeat: no-repeat;
    background-position: right 10px center; /* Position the arrow */
    background-size: 12px; /* Size of the arrow */
}

.niveau-dropdown:hover {
    transform: translate(-1px, -1px);
    box-shadow: 6px 6px var(--shadow-color);
}

.niveau-dropdown:focus {
    box-shadow: none;
    transform: translate(2px, 2px);
}

.create-button {
    margin-top: auto; /* Push the button to the bottom */
    margin-bottom: 10px; /* Add some margin at the bottom */
    align-self: center; /* Center the button horizontally */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 90px;
    border: 1px solid var(--border-color);
    background-color: var(--signature-color);
    color: var(--text-color);
    height: 32px;
    border-radius: 8px;
    font-family: "Courier New", monospace;
    font-weight: bold;
}

.tags-section label,
.topics-section label,
.languages-section label,
.niveau-section label {
    display: block;
    margin-bottom: 5px;
    font-weight: normal;
    font-size: 10pt;
    text-align: left; /* Align text to the left */
    background-color: transparent; /* Ensure background is transparent */
}

.vocabcreator-container .vocabcreator-name-input::placeholder {
    color: lightgray !important; /* Match the color of the description placeholder */
}

.vocabcreator-container .description-input::placeholder {
    color: lightgray !important;
}

.niveau-modal {
    position: absolute;
    background-color: var(--button-bg-color);
    border: 1px solid var(--border-color);
    border-radius: 30px;
    padding: 30px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    outline: none !important;
}

.niveau-modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent; /* Make overlay transparent */
    outline: none !important;
}

.niveau-modal h2 {
    margin: -20px 0 0px 0; /* Reduced top margin, increased bottom margin */
    font-size: 20px;
    text-align: center;
    background-color: transparent;
    font-family: "Courier New", monospace;
    font-weight: normal;
    color: var(--text-color);
}

.niveau-modal button {
    display: block;
    margin: 10px auto 0;
    padding: 5px 15px; /* Increased horizontal padding */
    border: none;
    border-radius: 20px;
    background-color: var(--signature-color);
    color: var(--text-color);
    cursor: pointer;
    border: 1px solid var(--border-color);
    font-family: "Courier New", monospace;
    font-weight: bold;
    text-align: center;
    height: 30px;
    /* Remove fixed width */
    min-width: 80px; /* Set minimum width */
    width: auto; /* Allow width to adjust to content */
}

.niveau-modal .react-slider {
    margin: 40px 0 65px 0; /* Increased bottom margin to create more space before button */
}

.niveau-modal .react-slider .thumb {
    background-color: var(--button-bg-color);
    border: 1px solid var(--border-color);
    box-shadow: 0 2px var(--border-color);
    border-radius: 5px;
    cursor: grab;
    top: 50%;
    transform: translate(0, -50%);
    outline: none;
    width: 16px;
    height: 16px;
}

.niveau-modal .react-slider .thumb:hover {
    transform: translate(0, -52%);
}

.niveau-modal .react-slider .thumb:active {
    cursor: grabbing;
    box-shadow: 0 1px var(--border-color);
    transform: translate(0, -49%);
}

.niveau-modal .react-slider .track {
    background: var(--button-bg-color);
    height: 4px;
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid var(--border-color);
}

.niveau-modal .react-slider .track-1 {
    background: var(--signature-color);
}

/* Label styles */
.niveau-modal .react-slider .thumb-label {
    position: absolute;
    top: -20px; /* Position the label above the thumb */
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    white-space: nowrap;
}

/* Remove the thumb label styles */
.niveau-modal .react-slider .thumb-label {
    display: none; /* or you can remove this block entirely */
}

/* Add new styles for the niveau labels above the slider */
.niveau-labels-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: -10px;
    margin-left: -12px;
    padding: 0 0px;
    position: relative;
    width: calc(100% - 20px);
    margin-top: 20px; /* Add some space at the top */
}

.niveau-label {
    font-size: 12px;
    color: var(--text-color);
    position: absolute;
    transform: translateX(-50%);
    background-color: transparent; /* Ensure transparent background */
    left: calc(var(--left-position) - 8%); /* Shift labels slightly left */
    top: 5px; /* Move labels down a bit */
    font-family: "Courier New", monospace;
    font-weight: bold;
}

.tags-modal, .topics-modal {
    position: absolute;
    background-color: var(--button-bg-color);
    border: 1px solid var(--border-color);
    border-radius: 30px;
    padding: 30px;
    width: 300px;
    height: 220px; /* Fixed height to accommodate title, 3 rows, and button */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    outline: none !important;
    display: flex;
    flex-direction: column;
}

.modal-items {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 10px 0;
    background-color: transparent;
    height: 120px; /* Fixed height for approximately 3 rows */
    overflow-y: auto;
    padding: 10px;
    flex: 1; /* Allow this section to take available space */
}

.modal-item {
    height: 20px;
    display: flex;
    align-items: center;
    background-color: var(--button-bg-color);
    border: 1px solid var(--border-color);
    border-radius: 12px;
    padding: 3px 7px;
    cursor: pointer;
    transition: background-color 0.2s;
    font-family: "Courier New", monospace;
    font-weight: normal;
    font-size: 10pt;
    color: var(--text-color);
}

.modal-item.selected {
    background-color: var(--signature-color);
}

.tag, .topic {
    cursor: pointer;
}

.tags-modal h2, .topics-modal h2 {
    margin: -20px 0 0px 0;
    font-size: 20px;
    text-align: center;
    background-color: transparent;
    font-family: "Courier New", monospace;
    font-weight: normal;
    color: var(--text-color);
}

.modal-items:focus, 
.modal-item:focus,
.niveau-modal:focus,
.niveau-modal *:focus {  /* This targets the niveau modal and all its children */
    outline: none !important;
    box-shadow: none !important;
}

/* If there are any modal overlays that need the same treatment */
.niveau-modal-overlay:focus,
.tags-modal-overlay:focus,
.topics-modal-overlay:focus {
    outline: none !important;
}

.tags-modal button, .topics-modal button, .niveau-modal button {
    display: block;
    margin: 10px auto 0;
    padding: 5px 15px;
    border: 1px solid var(--border-color);
    border-radius: 20px;
    background-color: var(--signature-color);
    color: var(--text-color);
    cursor: pointer;
    font-family: "Courier New", monospace;
    font-weight: bold;
    text-align: center;
    height: 30px;
    min-width: 80px;
    width: auto;
}



/* Update the overflow property */
.create-deck,
.tags-modal .modal-items,
.topics-modal .modal-items {
    overflow-y: auto;  /* Changed from overlay to auto */
}

.language {
    cursor: pointer;
    background-color: var(--signature-color);
    border-radius: 12px;
    padding: 3px 7px;
    font-weight: bold;
    font-size: 10pt;
    border: 1px solid var(--border-color);
    height: 24px;
    font-family: "Courier New", monospace;
    display: flex;
    align-items: center;
}

/* Only show hover effects when not in created state */
.language:not([style*="cursor: not-allowed"]):hover {
    background-color: #ff5c5c;
    text-decoration: line-through;
}

/* Add hover effect to show it's clickable for removal */
.tag:hover, .topic:hover, .language:hover {
    background-color: #ff5c5c; /* Light red background on hover */
    text-decoration: line-through; /* Strikethrough effect */
}

/* Common styles for all containers */
.create-deck, .middle-container-top, .middle-container-bottom, .right-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0; /* Ensure no margins interfere with gap */
    background-color: var(--button-bg-color);
    border: 1px solid var(--border-color);
    border-radius: 30px;
    padding: 20px;
    padding-top: 0;
    overflow-y: auto;
    box-sizing: border-box;
}

/* Specific heights for middle containers */
.middle-container-top, .middle-container-bottom {
    height: 38vh; /* Slightly less than half of create-deck */
    margin: 0; /* Remove margin as it's handled by column gap */
}

/* Match create-deck height for right container */
.right-container {
    height: 80vh;
    margin: 0; /* Remove margin as it's handled by column gap */
}

/* Apply the same scrollbar styling to all containers */
.create-deck::-webkit-scrollbar,
.middle-container-top::-webkit-scrollbar,
.middle-container-bottom::-webkit-scrollbar,
.right-container::-webkit-scrollbar {
    width: 6px;
    background: transparent;
}

.create-deck::-webkit-scrollbar-thumb,
.middle-container-top::-webkit-scrollbar-thumb,
.middle-container-bottom::-webkit-scrollbar-thumb,
.right-container::-webkit-scrollbar-thumb {
    background-color: #cecece;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: content-box;
}

.create-deck::-webkit-scrollbar-track,
.middle-container-top::-webkit-scrollbar-track,
.middle-container-bottom::-webkit-scrollbar-track,
.right-container::-webkit-scrollbar-track {
    margin-top: 5vh;
    margin-bottom: 5vh;
    background-color: #f0f0f0;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: content-box;
}

/* Vocabulary Input Styles */
.vocabulary-input-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.vocab-input-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.vocab-input-group label {
    font-size: 12px;
    font-weight: bold;
}

.vocab-input-group input {
    padding: 8px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-family: "Courier New", monospace;
}

.vocabulary-input-container button {
    margin-top: 10px;
    padding: 8px;
    background-color: var(--signature-color);
    border: 1px solid var(--border-color);
    border-radius: 20px;
    cursor: pointer;
    font-family: "Courier New", monospace;
    font-weight: bold;
}

/* Vocabulary Table Styles */
.vocabulary-table {
    width: 100%;
    overflow: auto;
    margin-top: 20px; /* Add margin below the header */
    background-color: transparent;
    flex-grow: 1; /* Allow table to expand */
    display: flex;
    flex-direction: column;
}

/* Table styling */
.vocabulary-table table {
    width: auto;
    min-width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    background-color: transparent;
}

.vocabulary-table th,
.vocabulary-table td {
    padding: 8px 16px;
    text-align: left;
    font-size: 14px;
    background-color: transparent !important;
    white-space: nowrap;
    min-width: 100px;
    border-bottom: 0.5px solid var(--border-color);
    border-right: 0.5px solid var(--border-color);
}

/* Remove right border from last column */
.vocabulary-table th:last-child,
.vocabulary-table td:last-child {
    border-right: none;
}

/* Remove bottom border from last row */
.vocabulary-table tr:last-child td {
    border-bottom: none;
}

.vocabulary-table th {
    font-weight: normal;
    border-bottom: 1px solid var(--border-color);
    background-color: transparent !important;
}

.vocabulary-table td {
    font-weight: normal;
}

/* Custom scrollbar for the table */
.vocabulary-table::-webkit-scrollbar {
    width: 6px;
    height: 6px; /* For horizontal scrollbar */
    background: transparent;
}

.vocabulary-table::-webkit-scrollbar-thumb {
    background-color: #cecece;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: content-box;
}

.vocabulary-table::-webkit-scrollbar-track {
    margin: 5vh;
    background-color: #f0f0f0;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: content-box;
}

/* Update right container to better handle the table */
.right-container {
    display: flex;
    flex-direction: column;
}

.deck-header {
    flex-shrink: 0; /* Prevent header from shrinking */
    padding: 20px;
    border-bottom: 1px solid var(--border-color);
    background-color: transparent;
    margin-bottom: 0; /* Remove bottom margin */
}

/* Ensure table cells have transparent background */
.vocabulary-table tr,
.vocabulary-table th,
.vocabulary-table td {
    background-color: transparent !important;
}

/* Handle horizontal scrollbar corner */
.vocabulary-table::-webkit-scrollbar-corner {
    background: transparent;
}

/* Deck Summary Styles */
.deck-summary {
    padding: 40px 20px 20px 20px; /* Increased top padding */
    display: flex;
    flex-direction: column;
    height: 100%;
}

.deck-summary p {
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    background-color: transparent;
}

.deck-summary strong {
    font-weight: bold;
}

/* Disabled state styles */
.create-button:disabled,
.vocabcreator-name-input:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

/* Add these new styles */

/* Deck Header in right container */
.deck-header {
    padding: 20px;
    border-bottom: 1px solid var(--border-color);
    background-color: transparent;
}

.deck-header h2 {
    margin: 0;
    font-size: 24px;
    color: var(--text-color);
}

.deck-header p {
    margin: 5px 0 0 0;
    font-size: 14px;
    color: var(--text-color);
    opacity: 0.8;
}

.deck-header p span.element-count {
    color: var(--widget-red);
    font-weight: bold;
}

.deck-header p span.element-text {
    font-weight: normal;
}

/* Button Containers */
.vocab-buttons, .deck-summary-buttons {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: 35px;
}

/* Common Button Styles (matching create-button) */
.vocab-buttons button,
.deck-summary-buttons button {
    padding: 10px 20px;
    border: 1px solid var(--border-color);
    background-color: var(--signature-color);
    color: var(--text-color);
    height: 32px;
    border-radius: 8px;
    font-family: "Courier New", monospace;
    font-weight: bold;
    cursor: pointer;
    min-width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Update existing vocabulary-input-container */
.vocabulary-input-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Update existing deck-summary */
.deck-summary {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Hover effects for all buttons */
.vocab-buttons button:hover,
.deck-summary-buttons button:hover,
.create-button:hover {
    transform: translate(-1px, -1px);
    box-shadow: 2px 2px 0px var(--shadow-border), 4px 4px var(--shadow-color);
}

/* Active effects for all buttons */
.vocab-buttons button:active,
.deck-summary-buttons button:active,
.create-button:active {
    transform: translate(1px, 1px);
    box-shadow: none;
}

/* Update container backgrounds */
.create-deck, .middle-container-top, .middle-container-bottom, .right-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0; /* Ensure no margins interfere with gap */
    background-color: var(--button-bg-color);
    border: 1px solid var(--border-color);
    border-radius: 30px;
    padding: 20px;
    padding-top: 0;
    overflow-y: auto;
    box-sizing: border-box;
}

/* Update vocabulary input styling to match name input */
.vocab-input-group input {
    display: block !important;
    width: 100% !important;
    box-sizing: border-box !important;
    border: none !important;
    border-bottom: 0.5px solid var(--border-color) !important;
    padding: 5px 0 5px 0 !important;
    margin: 0 !important;
    font-size: 11pt !important;
    border-radius: 0 !important;
    font-weight: normal !important;
    background-color: transparent !important;
    font-family: "Courier New", monospace !important;
    color: var(--text-color) !important;
    transition: none !important;
    box-shadow: none !important;
}

/* Update vocabulary table spacing */
.vocabulary-table {
    width: 100%;
    overflow-x: auto;
    margin-top: 20px; /* Add margin below the header */
    background-color: transparent;
}

/* Ensure all containers and their children have transparent backgrounds */
.vocabulary-input-container,
.deck-summary,
.deck-header,
.vocabulary-table,
.vocabulary-table table,
.vocabulary-table th,
.vocabulary-table td,
.vocab-input-group,
.vocab-buttons,
.deck-summary-buttons,
.input-group,
.tags-section,
.topics-section,
.languages-section,
.niveau-section {
    background-color: transparent !important;
}

/* Update table header styling */
.vocabulary-table th {
    font-weight: bold;
    border-bottom: 1px solid var(--border-color);
    background-color: transparent !important;
}

/* Ensure input placeholders are styled consistently */
.vocab-input-group input::placeholder {
    color: lightgray !important;
}

/* Remove any background colors from hover states */
.vocab-input-group input:hover,
.vocab-input-group input:focus {
    background-color: transparent !important;
}

/* Update table cell borders to be more subtle */
.vocabulary-table td {
    border-bottom: 0.5px solid var(--border-color);
    background-color: transparent !important;
}

/* Update input hover states */
.vocab-input-group input:hover, 
.vocab-input-group input:focus {
    background-color: transparent !important;
    box-shadow: none !important;
    transform: none !important;
    transition: none !important;
}

/* Update deck summary styling */
.deck-summary {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px; /* Reduced gap between lines */
    text-align: left;
}

.deck-summary p {
    margin: 0; /* Remove default margins */
    font-size: 14px;
    font-weight: normal;
    background-color: transparent;
}

/* Update vocab input group label styling */
.vocab-input-group label {
    font-size: 10pt !important; /* Match other label sizes */
    font-weight: normal !important;
    text-align: left !important;
    background-color: transparent !important;
    margin-bottom: 5px !important;
}

/* Ensure the vocab input group itself is transparent */
.vocab-input-group {
    background-color: transparent !important;
    text-align: left !important;
}

/* Update heading positioning */
.vocabulary-input-container h3,
.deck-summary h3 {
    margin: -0px 0 0px 0; /* Match the create-deck h3 margin */
    font-size: 22px;
    text-align: center;
    background-color: transparent;
    font-family: "Courier New", monospace;
    font-weight: 400;
}

/* Update button containers with increased spacing */
.vocab-buttons, .deck-summary-buttons {
    display: flex;
    gap: 20px; /* Increased gap between buttons */
    justify-content: center;
    margin-top: 15px;
}

/* Update button styling to handle variable width */
.vocab-buttons button,
.deck-summary-buttons button {
    padding: 10px 20px;
    border: 1px solid var(--border-color);
    background-color: var(--signature-color);
    color: var(--text-color);
    height: 32px;
    border-radius: 8px;
    font-family: "Courier New", monospace;
    font-weight: bold;
    cursor: pointer;
    min-width: 90px; /* Minimum width */
    width: auto; /* Allow width to grow */
    white-space: nowrap; /* Prevent text wrapping */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Update deck summary container */
.deck-summary {
    padding: 40px 20px 20px 20px; /* Increased top padding */
    display: flex;
    flex-direction: column;
    height: 100%;
}

/* Update deck summary h3 */
.deck-summary h3 {
    margin: -20px 0 40px 0; /* Adjust negative margin to match new padding */
    font-size: 22px;
    text-align: center;
    background-color: transparent;
    font-family: "Courier New", monospace;
    font-weight: 400;
}

/* Update deck summary information lines */
.deck-summary p {
    margin: -7px; /* Remove default margins */
    font-size: 14px;
    font-weight: normal;
    background-color: transparent;
}

/* Container for the summary information */
.deck-summary > p {
    margin-bottom: 3px; /* Reduced space between info lines */
}

/* Button container positioning */
.deck-summary-buttons {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: auto; /* Push buttons to bottom */
    margin-bottom: 10px; /* Add margin at the bottom */
}

/* Add this to your existing styles */
.deck-summary p .element-count {
    color: var(--widget-red);
    font-weight: bold;
}

.deck-summary p .element-text {
    font-weight: normal;
}

/* Add this to your existing styles */
.deck-summary .deck-title {
    font-weight: bold;
}

/* Add these styles to your CSS file */
.locked-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 15px;
    color: var(--text-color);
    opacity: 0.5;
    background-color: transparent !important;
}

.locked-container .lock-icon {
    font-size: 32px !important;
    color: var(--text-color);
}

.locked-container p {
    font-family: "Courier New", monospace;
    font-size: 14px;
    text-align: center;
    margin: 0;
    background-color: transparent !important;
    font-weight: normal !important; 
}

/* Update the language styles */
.language {
    cursor: pointer;
    background-color: var(--signature-color);
    border-radius: 12px;
    padding: 3px 7px;
    font-weight: bold;
    font-size: 10pt;
    border: 1px solid var(--text-color);
    height: 24px;
    font-family: "Courier New", monospace;
    display: flex;
    align-items: center;
}

/* Only show delete hover effects when not in created state */
.language:not([style*="cursor: not-allowed"]):hover {
    background-color: #ff5c5c;
    text-decoration: line-through;
}

/* Add disabled state styling */
.language[style*="cursor: not-allowed"] {
    opacity: 0.6;
    cursor: not-allowed;
}

/* Remove hover effects for disabled state */
.language[style*="cursor: not-allowed"]:hover {
    background-color: var(--signature-color);
    text-decoration: none;
}

/* Add these styles for the table row hover effect */
.vocabulary-row {
    cursor: pointer;
    transition: background-color 0.2s;
}

.vocabulary-row:hover {
    background-color: #ff5c5c !important;
    position: relative;
}

.vocabulary-row:hover::after {
    content: '';
    position: absolute;
    left: 10px;  /* Match the cell padding */
    right: 10px; /* Match the cell padding */
    top: 50%;
    border-top: 1px solid var(--text-color);
    pointer-events: none;
}

/* Remove the individual cell strikethrough */
.vocabulary-row:hover td {
    text-decoration: none;
    color: var(--text-color);
}

/* Add styles for the export button */
.export-button {
    padding: 10px 20px;
    border: 1px solid var(--border-color);
    background-color: var(--signature-color);
    color: var(--text-color);
    height: 32px;
    border-radius: 8px;
    font-family: "Courier New", monospace;
    font-weight: bold;
    cursor: pointer;
    min-width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.export-button:hover {
    transform: translate(-1px, -1px);
    box-shadow: 2px 2px 0px var(--shadow-border), 4px 4px var(--shadow-color);
}

.export-button:active {
    transform: translate(1px, 1px);
    box-shadow: none;
}

/* Update deck-summary-buttons to handle three buttons */
.deck-summary-buttons {
    display: flex;
    gap: 15px; /* Slightly reduced gap to accommodate three buttons */
    justify-content: center;
    margin-top: auto;
    margin-bottom: 10px;
}

/* Add these styles */
.create-deck-buttons {
    margin-top: auto;
    margin-bottom: 10px;
    display: flex;
    gap: 20px;
    justify-content: center;
}

.create-deck-buttons button {
    padding: 10px 20px;
    border: 1px solid var(--border-color);
    background-color: var(--signature-color);
    color: var(--text-color);
    height: 32px;
    border-radius: 8px;
    font-family: "Courier New", monospace;
    font-weight: bold;
    cursor: pointer;
    min-width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.create-deck-buttons button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.create-deck-buttons button:not(:disabled):hover {
    transform: translate(-1px, -1px);
    box-shadow: 2px 2px 0px var(--shadow-border), 4px 4px var(--shadow-color);
}

.create-deck-buttons button:not(:disabled):active {
    transform: translate(1px, 1px);
    box-shadow: none;
}

/* Update container shadows */
.create-deck, .middle-container-top, .middle-container-bottom, .right-container {
    /* ... existing styles ... */
    box-shadow: 2px 2px 0px var(--shadow-border), 4px 4px var(--shadow-color);
    transition: all 0.05s;
}

/* Update tag, topic, and language styles */
.tag, .topic, .language {
    /* ... existing styles ... */
    box-shadow: 2px 2px 0px var(--shadow-border), 4px 4px var(--shadow-color);
    transition: all 0.05s;
}

/* Update hover effects */
.tag:hover, .topic:hover, .language:hover {
    background-color: #ff5c5c;
    text-decoration: line-through;
    transform: translate(-1px, -1px);
    box-shadow: 3px 3px 0px var(--shadow-border), 6px 6px var(--shadow-color);
}

/* Update active effects */
.tag:active, .topic:active, .language:active {
    transform: translate(1px, 1px);
    box-shadow: none;
}

/* Ensure disabled language items don't show shadow effects */
.language[style*="cursor: not-allowed"] {
    box-shadow: none;
    transform: none;
}

.language[style*="cursor: not-allowed"]:hover {
    box-shadow: none;
    transform: none;
}

/* Common button styles */
.create-button,
.vocab-buttons button,
.deck-summary-buttons button,
.export-button,
.create-deck-buttons button,
.tags-modal button,
.topics-modal button,
.niveau-modal button {
    /* ... existing styles ... */
    box-shadow: 2px 2px 0px var(--shadow-border), 4px 4px var(--shadow-color);
    transition: all 0.05s;
}

/* Common hover effects for all buttons */
.create-button:not(:disabled):hover,
.vocab-buttons button:not(:disabled):hover,
.deck-summary-buttons button:not(:disabled):hover,
.export-button:not(:disabled):hover,
.create-deck-buttons button:not(:disabled):hover,
.tags-modal button:hover,
.topics-modal button:hover,
.niveau-modal button:hover {
    transform: translate(-1px, -1px);
    box-shadow: 3px 3px 0px var(--shadow-border), 6px 6px var(--shadow-color);
}

/* Common active effects for all buttons */
.create-button:not(:disabled):active,
.vocab-buttons button:not(:disabled):active,
.deck-summary-buttons button:not(:disabled):active,
.export-button:not(:disabled):active,
.create-deck-buttons button:not(:disabled):active,
.tags-modal button:active,
.topics-modal button:active,
.niveau-modal button:active {
    transform: translate(1px, 1px);
    box-shadow: none;
}

/* Disabled button states */
.create-button:disabled,
.vocab-buttons button:disabled,
.deck-summary-buttons button:disabled,
.export-button:disabled,
.create-deck-buttons button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    box-shadow: none;
    transform: none;
}


