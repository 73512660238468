/* Widget grid layout */
.widget-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 20px;
  width: 100%;
  height: 80%;
  margin: 0 auto;
  padding: 40px;
  padding-top: 120px;
  background-color: transparent;
}

/* Add this media query for mobile responsiveness */
@media (max-width: 900px) {
  .widget-grid {
    margin-top: 40px;
    grid-template-columns: 1fr; /* Stack widgets vertically */
    width: 80vw; /* Set width to 80vw */
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: 120vh; /* Set a maximum height for the grid */
    margin-bottom: 150px;
    margin-top: 150px;
    /* Hide scrollbar */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }

  .widget-grid::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }

  .widget {
    height: 150px; /* Define a uniform height for all widgets */
  }
}

/* Widget styles */
.widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Courier New", monospace;
  font-size: 18px;
  font-weight: 600;
  color: var(--font-color);
  background-color: var(--bg-color);
  border: 1px solid var(--border-color);
  box-shadow: 4px 4px 0px var(--shadow-border), 9px 9px 0px var(--shadow-color);
  /* border-radius: 5px;*/
  text-align: center;
  padding: 20px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

/* Widget hover effect */
.widget::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -150%;
  width: 300%;
  height: 300%;
  background-color: black;
  border-radius: 50%;
  transform: translateX(-50%) scale(0);
  transition: transform 0.5s ease-out;
}

.widget:hover::before {
  transform: translateX(-50%) scale(1);
}

.widget::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 150%;
  height: 150%;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 25%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.1) 75%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: translateX(-100%) translateY(-100%) rotate(45deg);
  pointer-events: none;
  opacity: 0;
}

.widget:hover::after {
  animation: glassPass 0.8s ease-in-out 0.5s forwards;
}

@keyframes glassPass {
  0% {
    transform: translateX(-100%) translateY(-100%) rotate(45deg);
    opacity: 0.7;
  }
  100% {
    transform: translateX(100%) translateY(100%) rotate(45deg);
    opacity: 0;
  }
}

.widget:hover {
  transform: translate(-4px, -4px);
  box-shadow: 8px 8px 0px var(--shadow-border),15px 15px 0px var(--shadow-color);
  border: 2px solid var(--border-color);
}

.widget:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0px var(--shadow-color);
}

/* Widget content styling */
.widget h3 {
  margin: 10px 0 0 0;
  color: var(--text-color);
  background-color: transparent;
}

/* MUI Icon Styling */
.widget-icon {
  width: 64px;
  height: 64px;
  transition: all 0.3s ease;
  background-color: transparent;
  color: var(--text-color);
}

.widget:hover .widget-icon {
  animation: spin-and-zoom 2s cubic-bezier(0.25, 0.8, 0.25, 1) infinite;
  fill: white;
}

.widget:hover h3 {
  animation: spin-and-zoom 2s cubic-bezier(0.25, 0.8, 0.25, 1) infinite;
  color: white;
  display: none;
}

@keyframes spin-and-zoom {
  0% {
    transform: rotate(0deg) scale(2);
  }
  50% {
    transform: rotate(360deg) scale(4);
  }
  100% {
    transform: rotate(720deg) scale(2);
  }
}

/* Widget sizes */
.widget-4x4 {
  grid-column: span 4;
  grid-row: span 4;
}

.widget-3x2 {
  grid-column: span 3;
  grid-row: span 2;
}

.widget-2x4 {
  grid-column: span 2;
  grid-row: span 4;
}

.widget-2x3 {
  grid-column: span 2;
  grid-row: span 3;
}

.widget-2x2 {
  grid-column: span 2;
  grid-row: span 2;
}

.widget-2x1 {
  grid-column: span 2;
  grid-row: span 1;
}

/* Background colors for individual widgets */
#vocabulary-widget {
  background-color: var(--widget-blue);
}

#grammar-widget {
  background-color: var(--widget-violet);
}

#stories-widget {
  background-color: var(--widget-pink);
}

#media-widget {
  background-color: var(--widget-green);
}

#lessons-widget {
  background-color: var(--widget-red);
}



