.german-lessons {
  min-height: 100vh;
  background-color: var(--background-color);
}

.content {
  display: flex;
}

.sidebar {
  width: 27vw;
  background-color: var(--background-color);
  box-shadow: 6px 0 6px rgba(0, 0, 0, 0.1);
  border-right: 1px solid var(--border-color);
  padding: 20px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  z-index: 6;
}

.lessons-container {
  flex: 1;
  padding-left: 20px;
  margin-left: 33vw;
  margin-right: 5vw;
  padding-top: 120px;
  padding-bottom: 80px;
  width: 76vw;
  align-items: center;
  justify-content: center;
  position: relative;
}

.lessons-container::before,
.lessons-container::after {
  content: '';
  position: fixed;
  left: 31vw;
  right: 0;
  height: 110px;
  pointer-events: none;
  z-index: 4;
}

.lessons-container::before {
  top: 0;
  background: linear-gradient(to bottom, 
    var(--background-color) 0%,
    var(--background-color) 50%,
    rgba(var(--background-color-rgb), 0) 100%
  );
}

.lessons-container::after {
  bottom: 0;
  background: linear-gradient(to top, 
    var(--background-color) 0%,
    var(--background-color) 40%,
    rgba(var(--background-color-rgb), 0) 100%
  );
}

.lesson-card {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--button-bg-color);
  padding: 10px 20px;
  border-radius: 40px;
  margin-bottom: 15px;
  cursor: pointer;
  font-family: "Courier New", monospace;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  border: 1px solid var(--border-color);
  box-shadow: 2px 2px 0px var(--shadow-border), 6px 6px 0px var(--shadow-color);
  height: 20px;
  min-height: 20px;
}

.lesson-card:hover {
  transform: translate(-2px, -2px);
  box-shadow: 4px 4px 0px var(--shadow-border), 10px 10px 0px var(--shadow-color);
}

.lesson-card:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0px var(--shadow-color);
}

.lesson-icon, .test-icon {
  margin-right: 20px;  /* Add consistent margin for both icons */
  min-width: 24px;     /* Ensure icons have consistent width */
  color: var(--text-color);
}

/* If needed, specifically target test icons */
.test-icon {
  margin-right: 20px !important;  /* Force the same margin */
}

.lesson-info {
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.classifier {
  font-weight: bold;
  color: var(--text-color);
  font-family: "Courier New", monospace;
  font-size: 16px;
  width: 100px;
  min-width: 100px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}

/* Add extended background using pseudo-element */
.classifier::before {
  content: '';
  position: absolute;
  top: 0;
  left: -30px;
  right: -0px;
  bottom: 0;
  background-color: var(--button-bg-color);
  z-index: -1;
}

.title {
  width: 250px;
  color: var(--text-color);
  font-family: "Courier New", monospace;
  font-weight: normal;
  font-size: 16px;
  display: block;
  background-color: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  margin: 0;
  margin-right: 50px;
  line-height: 20px;
  position: relative;
  flex-shrink: 0;
  text-align: left;
  padding-left: 0;
  z-index: 1;
}

/* Add right-side filled shape that stays in place */
.lesson-info::before {
  content: '';
  position: absolute;
  right: 30px;
  top: 0;
  height: 100%;
  width: 300px;
  background-color: var(--button-bg-color);
  z-index: 2;
}

/* Add gradient fade-out effect */
.title::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 30px;
  background: linear-gradient(to right, transparent, var(--button-bg-color));
  pointer-events: none;
}

/* Hover animation */
.lesson-card:hover .title {
  overflow: visible;
  animation: scrollText calc(var(--content-width, 120) * 50ms) linear infinite;
}

@keyframes scrollText {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}

/* Remove gradient and show solid background on hover */
.lesson-card:hover .title::after {
  display: none;
}

/* Test card specific styling */
.test-card .lesson-info::before {
  background-color: var(--test-card-color);
}

.test-card .title::after {
  background: linear-gradient(to right, transparent, var(--test-card-color));
}

.duration {
  color: var(--text-color);
  margin: 0 20px;
  white-space: nowrap;
  font-family: "Courier New", monospace;
  margin-left: auto;
  z-index: 3;
}

.progress-section {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 200px;
  margin-right: 5px;
  z-index: 3;
}

/* Style the MUI LinearProgress */
.progress-bar {
  flex: 1;
  height: 8px !important; /* Make it slightly thicker */
  border-radius: 4px !important; /* Rounded corners for the container */
}

/* Style the progress bar background (not yet done part) */
.progress-bar .MuiLinearProgress-bar {
  background-color: var(--signature-color) !important; /* Done part */
  border-radius: 4px !important; /* Rounded corners for the progress part */
}

/* Style the background (not yet done part) */
.progress-bar.MuiLinearProgress-root {
  background-color: var(--progress-bar-color) !important; /* Not yet done part */
}

.progress-text {
  color: var(--text-color);
  white-space: nowrap;
  min-width: 45px;
  font-family: "Courier New", monospace;
}

.checkbox-icon {
  color: var(--text-color);
  margin-left: 0px;
  font-size: 18px !important;
  stroke-width: 1 !important;
  z-index: 3;
}   

.checkbox-checked {
  color: var(--checkbox-checked-color) !important;
}

/* Test card specific styling */
.test-card {
  background-color: var(--test-card-color);
}

.test-card .lesson-icon,
.test-card .classifier,
.test-card .title,
.test-card .duration,
.test-card .progress-text,
.test-card .checkbox-icon {
  color: var(--text-color);
}

/* Test progress bar specific styling */
.test-progress.progress-bar .MuiLinearProgress-bar {
  background-color: var(--signature-color) !important;
}

.test-progress.progress-bar.MuiLinearProgress-root {
  background-color: var(--progress-bar-color) !important;
}

/* Locked card styling */
.locked-card {
  opacity: 0.7;
  cursor: not-allowed;
}

.locked-card .lesson-icon,
.locked-card .classifier,
.locked-card .title,
.locked-card .duration,
.locked-card .progress-text,
.locked-card .checkbox-icon {
  color: var(--text-color);
}

.locked-card:hover {
  transform: none;
  box-shadow: 4px 4px 0px var(--shadow-border), 9px 9px 0px var(--shadow-color);
  border: 1px solid var(--border-color);
}

/* Add specific styling for the Searchbar within the sidebar */
.sidebar .input-container {
  margin-top: 10px;
  width: 80%;
}

.stats-container {
  width: 80%;
  background-color: var(--button-bg-color);
  border-radius: 15px;
  padding: 20px;
  border: 1px solid var(--border-color);
  box-shadow: 4px 4px 0px var(--shadow-border), 9px 9px 0px var(--shadow-color);
  font-family: "Courier New", monospace;
  color: var(--text-color);
}

.stats-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
}

.stats-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;
}

/* Add this new style for the total numbers */
.stats-row .total-count {
  color: var(--text-color);
}

.stats-divider {
  height: 1px;
  background-color: var(--border-color);
  margin: 15px 0;
}

.level-indicator {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  font-size: 14px;
  font-weight: normal;
}

.level-indicator span:last-child {
  font-weight: bold;
  color: var(--text-color);
}

.completed-value {
  font-weight: bold;
  color: var(--widget-green);
}

.level-value {
  font-weight: bold;
  color: var(--text-color);
}

/* Add new styles for the trainer buttons */
.trainer-buttons {
  width: 70%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  margin-top: 90px;
}

.trainer-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  background-color: var(--button-bg-color);
  padding: 12px 20px;
  border-radius: 15px;
  border: 1px solid var(--border-color);
  box-shadow: 2px 2px 0px var(--shadow-border), 6px 6px 0px var(--shadow-color);
  cursor: pointer;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  font-family: "Courier New", monospace;
  color: var(--text-color);
  white-space: nowrap; /* Prevent text wrapping */
}

.trainer-button:hover {
  transform: translate(-2px, -2px);
  box-shadow: 4px 4px 0px var(--shadow-border), 10px 10px 0px var(--shadow-color);
}

.trainer-button:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0px var(--shadow-color);
}

.trainer-icon {
  color: var(--text-color);
}

/* Add new header styles */
.language-header {
  position: fixed;
  top: 0;
  left: 29vw;
  right: 0;
  height: 80px;
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  padding-left: 6vw;
  z-index: 5;
}

.language-header h2 {
  font-family: "Courier New", monospace;
  font-size: 28px;
  color: var(--text-color);
  margin-left: 15px;
  font-weight: bold;
}

/* Add specific background for test cards */
.test-card .classifier::before {
  background-color: var(--test-card-color);
}
