@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

/* Container for the input field */
.input-container {
  position: relative;
  width: 100%;
  max-width: 300px;
  margin-top: 20px auto;
  margin-right: 40px;
}

/* The input field itself */
.input {
  width: 100%;
  height: 15px;
  padding: 12px;
  font-size: 18px;
  font-family: "Courier New", monospace;
  color: var(--text-color);  /* Keep text color black */
  background-color: var(--input-color);  /* Keep background white */
  border: 4px solid var(--border-color);
  border-radius: 0;
  outline: none;
  transition: all 0.3s ease;
  box-shadow: 3px 3px 0px var(--shadow-border),8px 8px 0 var(--shadow-color);
}

/* Placeholder styling */
.input::placeholder {
  color: var(--placeholder-color);
}

/* Hover state for the input field */
.input:hover {
  transform: translate(-4px, -4px);
  box-shadow: 8px 8px 0px var(--shadow-border),16px 16px 0 var(--shadow-color);
}

/* Focus state for the input field - No color change */
.input:focus {
  background-color: var(--input-color); /* Keep background color white */
  color: var(--text-color);            /* Keep text color black */
  border-color: var(--border-color);      /* Keep border black */
  animation: none;         /* Remove the shake animation */
}

/* Placeholder styling when input is focused */
.input:focus::placeholder {
  color: var(--placeholder-color);  /* Keep placeholder color unchanged */
}

/* Remove shaking animation on focus */
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(0);  /* No shake */
  }
  50% {
    transform: translateX(0);  /* No shake */
  }
  75% {
    transform: translateX(0);  /* No shake */
  }
  100% {
    transform: translateX(0);  /* No shake */
  }
}

/* Remove glitch animation when the input contains text */
.input:not(:placeholder-shown) {
  animation: none;  /* No glitch animation */
}



/* Remove bold and letter-spacing effect when typing */
.input:not(:placeholder-shown) {
  font-weight: bold;
  letter-spacing: normal;
  text-shadow: none;
}
