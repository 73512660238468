.language-selector-dropdown {
  height: 30px;
  border: 1px solid var(--border-color);
  background-color: var(--button-bg-color);
  box-shadow: 2px 2px 0px var(--shadow-border), 4px 4px var(--shadow-color);
  font-size: 14px;
  font-weight: 600;
  font-family: "Courier New", monospace;
  color: var(--text-color);
  padding: 5px 10px;
  cursor: pointer;
  outline: none;
  transition: all 0.05s;
}

.language-selector-dropdown:hover {
  transform: translate(-1px, -1px);
  box-shadow: 6px 6px var(--shadow-color);
}

.language-selector-dropdown:focus {
  box-shadow: none;
  transform: translate(2px, 2px);
}

.language-selector-dropdown option {
  font-size: 14px;
  font-weight: 600;
  font-family: "Courier New", monospace;
  color: var(--text-color);
  background-color: var(--button-bg-color);
  padding: 5px 10px;
  cursor: pointer;
}
