

:root[data-theme='light'] {
  --background-color-rgb: 245, 245, 245;
    --background-color: #B1D8BE;
    --border-color: #28302B;
    --text-color: #1D2320;
    --header-color: #124725;
    --shadow-color: rgba(73, 89, 78, 0.60);
    --header-shadow-color: rgba(73, 89, 78, 0.25);
    --shadow-border: transparent;
    --input-color: white;
    --button-bg-color: white;
    --placeholder-color: #888;
    --signature-color: #D9ACF5;
    --signature-border-color: #342A3A;
    --signature-font-color: #342A3A;
    --signature-shadow-color: #635070;


    --widget-blue: #6eb6e6;
    --widget-violet: #a388ee;
    --widget-green: #7fbc8c;
    --widget-red: #ff5c5c;
    --widget-pink: #ffb2ef;

    --checkbox-checked-color: #6665FF;
    --test-card-color: #EDE39D;
    --progress-bar-color: #313B35;
  }
  
  :root[data-theme='dark'] {
    --background-color-rgb: 245, 245, 245;
    --background-color: #202B24;
    --border-color: white;
    --text-color: white;
    --shadow-color: white;
    --shadow-border: #202B24;
    --input-color: #202B24;
    --button-bg-color: #202B24;
    --placeholder-color: #fff;
    --signature-color: #D75EFF;
    --signature-border-color: #ecc1fa;
    --signature-font-color: #f8e5ff;
    --signature-shadow-color: #dc7afd;


    --widget-blue: #6665FF;
    --widget-violet: #9900CC;
    --widget-green: #319966;
    --widget-red: #FE0000;
    --widget-pink: #e831ab;
    --header-color: #ffffff;

    --checkbox-checked-color: #6665FF;
    --test-card-color: #FFA500;
    --progress-bar-color: #313B35;
  }